import React from 'react';

import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import wolData from '../common/wolData';

const Header = () => {
  const logout = () => {
    localStorage.removeItem('jwt');
    window.location.href = wolData.urls.adminFrontUrl + "/sign-in"
  }

  const renderNavLinks = () => {
    if (localStorage.getItem("jwt") === null) {
      return (
        <Nav className="justify-content-end" style={{ width: "95%" }}>
          <Nav.Link href="/sign-in">Iniciar Sesión</Nav.Link>
        </Nav>
      )
    } else {
      const restrictedLinks = (localStorage.getItem("role") && localStorage.getItem("role") === 'god') ?
        <NavDropdown id={"header"} title={"Datos WoL"} align="end">
          <NavDropdown.Item href="/candidates">Candidatos</NavDropdown.Item>
          <NavDropdown.Item href="/jobs">Publicaciones</NavDropdown.Item>
          <NavDropdown.Item href="/employers">Empleadores</NavDropdown.Item>
          <NavDropdown.Item href="/workplaces">Workplaces</NavDropdown.Item>
          <NavDropdown.Item href="/workplaces-manager">Workplaces pendientes</NavDropdown.Item>
          <NavDropdown.Item href="/templates">Plantillas</NavDropdown.Item>
        </NavDropdown>
        :
        <React.Fragment>
          <Nav.Link href="/jobs">Publicaciones</Nav.Link>
          <Nav.Link href="/candidates">Candidatos</Nav.Link>
        </React.Fragment>

      return (
        <Nav className="justify-content-end" style={{ width: "95%" }}>
          <Nav.Link href="/summary">Resumen</Nav.Link>
          <Nav.Link href="/criteria-upload">Criteria</Nav.Link>
          {restrictedLinks}

          <Nav.Link href="#" onClick={logout}>Cerrar Sesión</Nav.Link>
        </Nav>
      )
    }
  }

  return (
    <Navbar className="navbar-custom" bg="white" expand="lg">
      <Navbar.Brand className="navbar-brand-custom" href="https://workon.law">
        <b style={{ color: '#6F1502', fontWeight: 400 }}>W</b>orkon<b style={{ color: '#6F1502', fontWeight: 400 }}>.L</b>aw
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="navbar-links" />
      <Navbar.Collapse id="navbar-links">
        {renderNavLinks()}
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header;