type Woldata = {
  isoCodes: { [key: number]: string };
  employerStateOptions: { [key: string]: string };
  urls: { [key: string]: string | undefined };
  defaultContent: Array<Content>;
}

type Content = {
  section: Section[];
}

type Section = {
  [key: string]: string,
}

const wolData: Woldata = {
  isoCodes: { 1: "CL", 2: "CO", 3: "MX" },

  employerStateOptions: {
    "created": "Creado",
    "trial_period": "Periodo de prueba",
    "trial_period_ended": "Periodo de prueba expirado",
    "active": "Activo",
    "active_subscriber": "Suscriptor activo"
  },

  urls: {
    homepageUrl: process.env.REACT_APP_HOMEPAGE_URL,
    employerFrontUrl: process.env.REACT_APP_WOL_EMPLOYER_FRONT_URL,
    APIUrl: process.env.REACT_APP_WOL_API_URL,
    adminFrontUrl: process.env.REACT_APP_WOL_ADMIN_FRONT_URL
  },

  defaultContent: [
    {
      "section": [
        {
          "subtitle": "Subtítulo"
        },
        {
          "paragraph": "Párrafo"
        },
        {
          "paragraph": "Párrafo 2"
        },
        {
          "paragraph": "Párrafo 3"
        },
      ]
    }
  ]
}

export default wolData;
