import { useEffect, useState } from "react"
import wolData from "../common/wolData"
import WolSpinner from "./commons/WolSpinner"
import {
  Container,
  Row,
  Col,
  Pagination,
  PageItem,
  Alert
} from "react-bootstrap";
import OtherWorkplace from "./OtherWorkplace";

type WolPagination = {
  per_page: number,
  total_pages: number,
  total_objects: number,
  current_page: number,
}

type Workplace = {
  id: number,
  name: string,
  candidate_id: number,
  candidate_email: string,
  candidate_site: string,
  candidate_cv_url: string,
  position: string,
}

type Results = {
  workplaces: Array<Workplace>,
  pagination: WolPagination,
}

const displayWorkplaceAlertMessage = (workplace: Workplace | null) => {
  if (workplace) {
    const { name, candidate_email } = workplace
    return <Alert variant='success'>Workplace <strong>{name}</strong> asignado exitosamente a {candidate_email}.</Alert>
  }
  return null;
}

const WorkplacesManager = () => {
  const [results, setResults] = useState<Results | null>(null);
  const [areResultsLoaded, setAreResultsLoaded] = useState(false);
  const [workplaceAssigned, setWorkplaceAssigned] = useState<Workplace | null>(null);

  const getData = async (currentPage: string | number) => {
    try {
      let queryString = "?&page=" + currentPage;
      const response = await fetch(wolData.urls.APIUrl + "/apollo/get_other_workplaces" + queryString, {
        headers: {
          'Accept': 'application/json',
          'Authorization': localStorage.getItem('jwt') || ''
        }
      })
      const data = await response.json()
      if (data.errors) {
        if (data.errors.invalid_token) {
          localStorage.removeItem('jwt');
          window.location.href = wolData.urls.adminFrontUrl + "/sign-in";
        }
      } else {
        setResults(data)
        setAreResultsLoaded(true)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getPagination = (options: WolPagination | undefined) => {
    if (options) {
      let items: Array<any> = [];
      for (let number = 1; number <= options.total_pages; number++) {
        items.push(
          <PageItem
            className='mb-2'
            key={number}
            active={number === options.current_page}
            onClick={(event) => {
              const { target } = event
              if (target) getData((target as HTMLButtonElement).innerText);
            }}
          >
            {number}
          </PageItem>,
        );
      }
      return (
        <Pagination size="sm" className="d-flex flex-wrap">{items}</Pagination>
      )
    }
  }

  useEffect(() => { getData(1) }, [])


  return !areResultsLoaded ? (
    <WolSpinner />
  ) : (
    <Container>
      <Row>
        <Col>
          <h1>Trabajos con workplace otro</h1>
          <Alert variant='light'>Esta sección muestra los trabajos ingresados en el módulo works con workplace <strong>Otro</strong> de candidatos con puntaje destacado. Cada item del listado permite crear y asignar un nuevo workplace al trabajo del candidato.</Alert>
          {displayWorkplaceAlertMessage(workplaceAssigned)}
        </Col>
      </Row>

      <Row>
        <Col style={{ padding: '0 0 10px 22px' }}>
          {`Total: ${results?.pagination.total_objects}`}
        </Col>
      </Row>

      <Row className={'font-weight-bold mb-4 p-2 text-center'}>
        <Col>País</Col>
        <Col>Candidato</Col>
        <Col>Cargo</Col>
        <Col>Nombre sugerido</Col>
        <Col>Acciones</Col>
      </Row>
      {results?.workplaces.map((w, index) => <OtherWorkplace workplace={w} key={`workplace-item${index}`} setWorkplaceAssigned={setWorkplaceAssigned} />)}
      {getPagination(results?.pagination)}
    </Container>
  )
}

export default WorkplacesManager;