import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import Header from "./components/Header";
import SignInForm from "./components/SignInForm";
import Summary from "./components/Summary";
import CandidateEditForm from './components/CandidateEditForm';
import Candidates from "./components/Candidates";
import CriteriaUpload from "./components/CriteriaUpload";
import Jobs from "./components/Jobs";
import JobForm from "./components/JobForm";
import Employers from "./components/Employers";
import Workplaces from './components/Workplaces';
import WorkplaceForm from './components/WorkplaceForm';
import EditJobForm from './components/EditJobForm';
import EmployerAppEvents from './components/EmployerAppEvents';
import UploadScoreManagerForm from './components/UploadScoreManagerForm';
import EmployerUsers from './components/EmployerUsers';
import EmployerUserForm from './components/EmployerUserForm';
import EditEmployerUserForm from './components/EditEmployerUserForm';
import UploadReport from './components/UploadReport'
import TemplateManager from './components/templateManager'
// import { TemplateEditForm } from "./components/templateForm";
import EditTemplateForm from "./components/EditTemplateForm";
import EmployerForm from './components/EmployerForm';
import CandidateEmails from './components/CandidateEmails';
import WorkplacesManager from './components/WorkplacesManager';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

function App() {
  const query = useQuery();

  return (
    <div className="App">

      {/* <Header /> */}
      <Switch>
        {/* Root path */}
        <Route exact path="/">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" />
            :
            <Redirect to="/summary" />
          }
        </Route>

        {/* SignIn path */}
        <Route path="/sign-in">
          {(localStorage.getItem("jwt") === null) ?
            <div>
              <Header />
              <SignInForm />
            </div>
            :
            <Redirect to="/summary" />
          }
        </Route>

        {/* Summary */}
        <Route path="/summary">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" />
            :
            <div>
              <Header />
              <Summary />
            </div>
          }
        </Route>

        {/* Edit Candidate */}
        <Route path="/candidates/:id" >
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" />
            :
            <div>
              <Header />
              <CandidateEditForm />
            </div>
          }
        </Route>

        {/* Candidates */}
        <Route path="/candidates">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" />
            :
            <div>
              <Header />
              <Candidates />
            </div>
          }
        </Route>

        {/* Criteria */}
        <Route path="/criteria-upload">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" />
            :
            <div>
              <Header />
              <CriteriaUpload />
            </div>
          }
        </Route>

        {/* New Job */}
        <Route path="/jobs/new">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" />
            :
            <div>
              <Header />
              <JobForm formType='new' />
            </div>
          }
        </Route>

        {/* Edit Job */}
        <Route path="/jobs/:id" children={<EditJobForm />} />

        {/* Jobs */}
        <Route path="/jobs">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" />
            :
            <div>
              <Header />
              <Jobs />
            </div>
          }
        </Route>

        <Route path="/employers/:id">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" />
            :
            <div>
              <Header />
              <EmployerForm />
            </div>
          }
        </Route>

        {/* Employers */}
        <Route path="/employers">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" />
            :
            <div>
              <Header />
              <Employers />
            </div>
          }
        </Route>

        <Route path="/workplaces/:id">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" />
            :
            <div>
              <Header />
              <WorkplaceForm />
            </div>
          }
        </Route>

        <Route path="/workplaces-manager">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" />
            :
            <div>
              <Header />
              <WorkplacesManager />
            </div>
          }
        </Route>

        <Route path="/workplaces">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" />
            :
            <div>
              <Header />
              <Workplaces />
            </div>
          }
        </Route>

        {/* Template manager */}
        <Route path="/templates/:id">
          <EditTemplateForm />
        </Route>

        <Route path="/templates">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" /> :
            <div><Header /><TemplateManager /></div>}
        </Route>


        {/* Upload Score Manager Form */}
        <Route path="/upload-score-manager">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" /> :
            <div><Header /><UploadScoreManagerForm jobId={query.get('job_id')} /></div>}
        </Route>

        <Route path="/upload-job-report">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" /> :
            <div><Header /><UploadReport jobId={query.get('job_id')} /></div>}
        </Route>

        {/* Employer App Events */}
        <Route path="/employer-logs">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" /> :
            <div><Header /><EmployerAppEvents employerId={query.get('employer_id')} /></div>}
        </Route>

        {/* New Employer User */}
        <Route path="/employer-users/new">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" /> :
            <div><Header /><EmployerUserForm formType='new' employerId={query.get('employer_id')} /></div>
          }
        </Route>

        {/* Edit Employer User */}
        <Route path="/employer-users/:id" children={<EditEmployerUserForm />} />

        {/* Employer Users */}
        <Route path="/employer-users">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" /> :
            <div><Header /><EmployerUsers employerId={query.get('employer_id')} /></div>}
        </Route>

        <Route path="/candidate_emails">
          {(localStorage.getItem("jwt") === null) ?
            <Redirect to="/sign-in" />
            :
            <div>
              <Header />
              <CandidateEmails />
            </div>
          }
        </Route>

      </Switch>

    </div>
  );
}
export default App;
