import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert
} from "react-bootstrap";

import { Formik } from 'formik';
import * as Yup from 'yup';

import wolData from '../common/wolData';

const SignInForm = () => {

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Ingrese email"),
    password: Yup.string().required("Ingrese contraseña"),
  });


  return (
    <Formik
      initialValues={{ email: "", password: "" }}

      validationSchema={validationSchema}

      onSubmit={(values, { setSubmitting, setStatus }) => {
        setSubmitting(true);

        setTimeout(() => {
          setSubmitting(false);
        }, 500);

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: values.email, password: values.password })
        };

        fetch((wolData.urls.APIUrl + "/apollo/sign_in"), requestOptions)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            if (data.errors) {
              if (data.errors.email) {
                setStatus({ email: data.errors.email })
              }
              if (data.errors.password) {
                setStatus({ password: data.errors.password })
              }
              if (data.errors.general) {
                setStatus({ general: data.errors.general })
              }
            } else {
              localStorage.setItem('jwt', data.auth_token)
              localStorage.setItem('role', data.role)

              setStatus({ successFormSubmission: true })
              window.location.href = wolData.urls.adminFrontUrl as string;
            }
          })
          .catch((errors) => {
            console.error(errors)
          });
      }
      }
    >
      {({ values,
        errors,
        status,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting }) => (
        <Container style={{ paddingBottom: '40px' }}>
          <Row>
            <Col>
              <h1>Inicio de Sesión <small>Brain</small></h1>

              {(status && status.general) ?
                <Alert variant='danger'>
                  Email y/o contraseña inválidos
                </Alert>
                :
                null
              }

              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="signinControlEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    size="lg"
                    className={touched.email && errors.email ? "error" : ""}
                  />
                  {touched.email && errors.email ? (
                    <div className="error-message">{errors.email}</div>
                  ) : null}
                </Form.Group>
                <br />
                <br />
                <Form.Group controlId="signinControlPassword">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    size="lg"
                    className={touched.password && errors.password ? "error" : ""}
                  />
                  {touched.password && errors.password ? (
                    <div className="error-message">{errors.password}</div>
                  ) : null}
                </Form.Group>
                <Button
                  size="lg"
                  variant="danger"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ marginTop: '25px' }}
                >
                  Enviar
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </Formik>
  )
}

export default SignInForm;